import { useEffect, useState } from "react";
import {
  FormContainer,
  FormItem,
  StyledInput,
  PButton,
  StyledTextArea,
  StyledSelect,
  EEAT,
  EEATWRAP,
  EEATText,
} from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Typography from "../../../components/Typography";
import { jsonData } from "../../../components/LocationsMap/_data";
import Image from "next/image";

// Filter out dealer locations and create state/city mapping
const createLocationOptions = () => {
  const stateMap = {};
  
  console.log('Total locations:', jsonData.features.length);
  
  // Add all non-dealer locations from jsonData
  jsonData.features.forEach(location => {
    const props = location.properties;
    console.log('Processing location:', {
      city: props.city,
      state: props.state,
      dealer: props.dealer,
      showroom: props.showroom
    });
    
    // Skip dealer locations
    if (props.dealer === 'yes') {
      console.log('Skipping dealer location:', props.city);
      return;
    }
    
    const state = props.state;
    const city = props.city ? props.city.trim() : '';
    
    if (!stateMap[state]) {
      stateMap[state] = new Set();
    }
    if (city) {
      stateMap[state].add(city);
      console.log('Added location:', city, state);
    }
  });

  const result = Object.keys(stateMap).sort().map(state => ({
    label: state,
    abbreviation: state,
    options: Array.from(stateMap[state]).sort().map(city => ({
      label: `${city}, ${state}`,
      value: [city, state]
    }))
  }));

  console.log('Final options:', JSON.stringify(result, null, 2));
  return result;
};

function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  failed,
  referralCode,
  showModal,
  setShowModal,
}) {
  const [urlParams, setUrlParams] = useState(null);
  const locationOptions = createLocationOptions();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
      form.setFieldsValue({
        email: urlParams.get("email"),
      });
    }

    return () => {
      setUrlParams(null);
    };
  }, [form]);

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer
        name="large-form"
        size="small"
        onFinish={handleSubmit}
        form={form}
      >
        <Row className="background-red" gutter={16}>
          <Col span={24}>
            <FormItem
              validateTrigger={"onBlur"}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <StyledInput placeholder={"Email address"} />
            </FormItem>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <FormItem
                  name={"city"}
                  rules={[
                    {
                      required: false,
                      message: "State required",
                    },
                  ]}
                >
                  <StyledSelect
                    placeholder="Location*"
                    optionFilterProp="children"
                    options={locationOptions.map((state) => ({
                      label: state.label,
                      options: state.options
                    }))}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem initialValue={referralCode} name={"referral_code"}>
              <StyledInput placeholder={"Promotional Code"} $primary={true} />
            </FormItem>
          </Col>
        </Row>
        <div aria-hidden="true" tabIndex={"-1"} className="form-bot1">
          <StyledInput placeholder="username" />
        </div>
        <FormItem>
          <PButton
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            role="link"
          />
        </FormItem>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>   
            <Image
              src="/images/eeat/EEAT-02.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="lifetime limited warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="A+ BBB Rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-04.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
      <Typography>{failed}</Typography>
    </div>
  );
}

export default FormConsultationLarge;
