import { Col, Row } from "antd";
import Typography from "../../../components/Typography";
import { Header, TextWrapper } from "./styled";
import useMobile from "../../../hooks/useMobile";
import Image from "next/image";
import Link from "next/link";
const HEADER_FONT_SIZE = 2.5;
const HEADER_FONT_SIZE_MOBILE = 1.8;

export default function Banner() {
  const isMobile = useMobile();
  const TEXT_ALIGN = "left";

  return (
    <Header>
      <div className="image-wrapper">
        <Image
          src={!isMobile ? "/images/home_page/closet-shoe-storage.jpg" : "/images/home_page/closet-shoe-storage.jpg"}
          layout="fill"
          priority
          objectFit="cover"
          quality={100}
          className="banner-image"
          alt="custom closets"
        />
      </div>
      <TextWrapper>
        <Typography
          color="white"
          as="h1"
          fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.1}rem` : `${HEADER_FONT_SIZE / 0.9}rem`}
          textAlign={TEXT_ALIGN}
          fontFamily="Roboto Light"
          lineHeight={ 1 * 1}
          className="headDropShadow"
        >
          <em>Life.</em> Organized
        </Typography>
        <Typography
          as="h1"
          href="/products/closets"
          color="white"
          fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.1}rem` : `${HEADER_FONT_SIZE / 0.9}rem`}
          textAlign={TEXT_ALIGN}
          fontFamily="Roboto Medium"
          className="headDropShadow"
          lineHeight={ 1 * 1}
        >
          with a Classy &nbsp;
          <Link href="/products/closets">
            <a className="custom-closet">Custom Closet</a>
          </Link>
        </Typography>
      </TextWrapper>
    </Header>
  );
}
