import React, { useRef, useEffect, useState, useContext } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import Footer from "../Footer";
import { IsDesktop, IsMobile } from "../ResponsiveContainers";
import { useModalContext } from "../../context/modalContext";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
const HolidayPopup = dynamic(() => import("../../containers/Modals/ModalHoliday"));
const SchedulePopup = dynamic(() => import("../../containers/SchedulePopup"));
import Loading from "../Loading";
import InfoBanner from "../InfoBanner";
import {
  LayoutContainer,
  HeaderContainer,
  MainContainer,
  ContainerWithMax,
  FooterContainer,
  HeaderContainerMobile,
} from "./styled";
import { useHeaderContext } from "../../context/HeaderProvider";
import MainContainerContext from "../../context/MainContainer";
import Head from "next/head";
import { LocationContext } from "../../context/LocationContext";

const AppLayout = ({ children, type, footerBG }) => {
  const location = useContext(LocationContext);
  const { isVisible } = useHeaderContext(); 
  const wrapperRef = useRef();
  const [showHolidayPopup, setShowHolidayPopup] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [isHolidayPopupDismissed, setIsHolidayPopupDismissed] = useState(false);
  const [cookies] = useCookies(["popup"]);

  const pageLoadTime = useRef(Date.now());

  const isPopupExpired = () => {
    if (!cookies.popup) return true;
    const lastPopupDate = dayjs(cookies.popup);
    const today = dayjs();
    return today.diff(lastPopupDate, 'day') >= 1;
  };

  // Set up the timer to show Holiday popup after 15 seconds
  useEffect(() => {
    if (isPopupExpired()) {
      const holidayPopupTimer = setTimeout(() => {
        setShowHolidayPopup(true);
      }, 15000); // 15 seconds

      return () => {
        clearTimeout(holidayPopupTimer);
      };
    }
  }, [cookies.popup]);

  // When Holiday popup is dismissed, set up the timer for SchedulePopup
  useEffect(() => {
    if (isHolidayPopupDismissed) {
      const timeSincePageLoad = Date.now() - pageLoadTime.current;
      const remainingTimeUntil75Seconds = 75000 - timeSincePageLoad;
      const delay = Math.max(remainingTimeUntil75Seconds, 0);

      const schedulePopupTimer = setTimeout(() => {
        setShowSchedulePopup(true);
      }, delay);

      return () => {
        clearTimeout(schedulePopupTimer);
      };
    }
  }, [isHolidayPopupDismissed]);

  const handleHolidayPopupDismiss = () => {
    setShowHolidayPopup(false);
    setIsHolidayPopupDismissed(true);
  };

  return (
    <>
      <LayoutContainer className="ant-layout">
        <Head>
          {/* ... */}
        </Head>

        {/* Display Holiday Popup based on state */}
        {showHolidayPopup && <HolidayPopup visible={true} onRequestClose={handleHolidayPopupDismiss} />}
        {showSchedulePopup && <SchedulePopup visible={true} />}

        <InfoBanner role="alert" type="dark" />
        <MainContainerContext.Provider
          value={{ getContainer: () => wrapperRef.current || window }}
        >
          <HeaderContainer isvisible={isVisible ? "yes" : "no"}>
            <Header />
          </HeaderContainer>
          <IsMobile>
            <HeaderContainerMobile>
              <HeaderMobile />
            </HeaderContainerMobile>
          </IsMobile>
          <MainContainer>
            <ContainerWithMax ref={wrapperRef}>{children}</ContainerWithMax>
          </MainContainer>
          <FooterContainer>
            <Footer footerBG={footerBG} />
          </FooterContainer>
        </MainContainerContext.Provider>
      </LayoutContainer>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["noHeader", "noFooter"]),
  footerBG: PropTypes.string,
};

export default AppLayout;
