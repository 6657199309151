import { useState, useCallback } from "react";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import ClassicFormConsultation from "../Consultation-Classic";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import TagManager from "react-gtm-module";

const CRM_URL = "/api/contact";
const EMAIL_URL = "/api/contact/email";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId, darkLabels }) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submitMultiple } = useFetch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (values) => {
    // Prevent double submission
    if (loading || submitting) return;
    
    setLoading(true);
    setSubmitting(true);
    
    let url = typeof window !== "undefined" ? window.location.href : "null";

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Get showroom location from selected option
    const [showroomCity, showroomState] = values.showroom.split(', ');
    
    const baseData = {
      ...values,
      client: 'classyclosets',
      queries: getQueryFromStorage(),
      user_id: window.uniqueVisitorId,
      visitorId: window.uniqueVisitorId,
      confidenceScore: window.confidenceScore,
      fingerprint: window.uniqueVisitorId,
      // User's actual location
      user_city: userLocation.city,
      user_state: userLocation.state,
      // Franchise/showroom location
      city: showroomCity,
      state: showroomState
    };

    console.log('Form submission data:', baseData);

    setGoogleAdwordsInfo(values);
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });

    try {
      await submitMultiple([
        {
          url: CRM_URL,
          method: "POST",
          data: baseData
        }
      ]);

      // Send Facebook conversion event
      console.log('Sending Facebook conversion event with data:', {
        first_name: values.full_name,
        email: values.email,
        phone: values.phone,
        event_time: Date.now()
      });

      const fbResponse = await fetch('/api/crm/fb-conversion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: values.full_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });
      
      const fbResult = await fbResponse.json();
      console.log('Facebook conversion response:', fbResult);

      setCookie("converted", "step_1", {
        path: "/",
        maxAge: 86400 * 7 // 7 days
      });

      // Store current showroom values before reset
      const currentShowroom = values.showroom;
      const currentTenantId = values.tenantId;

      form.resetFields();

      // Restore showroom selection
      form.setFieldsValue({
        showroom: currentShowroom,
        tenantId: currentTenantId,
        city: userLocation.city || '',
        state: userLocation.state || ''
      });

      setShowModal(true);
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setLoading(false);
      // Add a small delay before allowing resubmission
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    }
  }, [loading, submitting, form, setCookie, submitMultiple]);

  return (
    <ClassicFormConsultation
      variant={variant}
      dispatchDestination={dispatchDestination}
      submitButton={submitButton}
      formId={formId}
      referralCode={referralCode}
      darkLabels={darkLabels}
      handleSubmit={handleSubmit}
      loading={loading || submitting}
      form={form}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};

export default withTheme(FormConsultation);
