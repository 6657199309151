import { useEffect, useState, useContext } from "react";
import {
  FormContainer, FormItem, StyledInput, PButton, StyledTextArea, EEAT,
  EEATWRAP,
  EEATText,
  StyledLabel,
  StyledSelect,
} from "./styled";
import { Row, Col, Input, Form } from "antd";
import Image from 'next/image';
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import { jsonData } from "../../../components/LocationsMap/_data";
import { createGlobalStyle } from 'styled-components';
import { LocationContext } from "../../../context/LocationContext";

const GlobalStyle = createGlobalStyle`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  
  .ant-form-item-label {
    padding: 2px 0 0 0 !important;
  }
    .ant-form label {
    font-size: 11px;
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px;
    color: white !important;
    font-size: 0.8rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
  }
`;

export default function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton,
  dispatchDestination,
  formId,
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);

  // Filter locations from jsonData - include all non-dealer locations
  const showroomOptions = jsonData.features
    .filter(location => {
      // Skip dealer locations
      if (location.properties.dealer === 'yes') return false;
      // Ensure we have valid city and state
      return location.properties.city && location.properties.state;
    })
    .map(location => {
      const city = location.properties.city.trim();
      const state = location.properties.state.trim();
      return {
        value: `${city}, ${state}`,
        label: `${city}, ${state}`,
        data: {
          ...location,
          properties: {
            ...location.properties,
            city,
            state
          }
        }
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));  // Sort alphabetically

  // Watch for location changes and update showroom selection
  useEffect(() => {
    if (location && !selectedShowroom) {
      // Get user's actual location from localStorage
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
        }
      } catch (error) {
        console.error('Error reading user location:', error);
      }

      // Get closest showroom from localStorage
      let closestShowroom = null;
      try {
        const storedShowroom = localStorage.getItem('locationFetched');
        if (storedShowroom) {
          closestShowroom = JSON.parse(storedShowroom);
          const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
          const matchingShowroom = showroomOptions.find(option => 
            option.value === showroomValue
          );

          if (matchingShowroom) {
            console.log('Setting initial showroom from location:', matchingShowroom.data);
            setSelectedShowroom(showroomValue);
            setShowroomData(matchingShowroom.data);
            
            form.setFieldsValue({
              showroom: showroomValue,
              tenantId: matchingShowroom.data.properties.tenantId,
              city: userLocation.city || '',
              state: userLocation.state || ''
            });
          }
        }
      } catch (error) {
        console.error('Error reading closest showroom:', error);
      }
    }
  }, [location, form, selectedShowroom, showroomOptions]);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption && location?.userLocation) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      // Keep user's actual location when changing showroom
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId,
        city: location.userLocation?.city || '',
        state: location.userLocation?.state || ''
      });
    }
  };

  const onFinish = (values) => {
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Create submission data object with user's actual location
    const submissionData = {
      ...values,
      ...urlParams,
      // User's actual location
      user_city: userLocation.city || '',
      user_state: userLocation.state || '',
      // Franchise/showroom location
      city: selectedOption.data.properties.city.trim(),
      state: selectedOption.data.properties.state,
      tenantId: selectedOption.data.properties.tenantId,
      source_id: '12',
      selectedShowroom: values.showroom // Keep track of selected showroom separately
    };

    console.log('Form values:', values);
    console.log('User location:', userLocation);
    console.log('Showroom location:', selectedOption.data.properties);
    console.log('Final submission data:', submissionData);

    handleSubmit(submissionData);
  };

  return (
    <div>
      <GlobalStyle />
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <Form 
        name={formId}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          tenantId: showroomData?.properties?.tenantId
        }}
      >
        <Row className="background-red" gutter={16}>
          <Col className="left-side" md={12} xs={24}>
            <Form.Item
              name="full_name"
              rules={[{ required: true, message: "Full Name required" }]}
              label={<StyledLabel>Full Name</StyledLabel>}
            >
              <StyledInput placeholder="Jane/John Doe" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Email required" },
                { type: "email", message: "Not a valid email" }
              ]}
              label={<StyledLabel>Email Address</StyledLabel>}
            >
              <StyledInput placeholder="user@email.com" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "Phone required" }]}
              label={<StyledLabel>Phone Number</StyledLabel>}
              normalize={(value) => {
                if (!value) return value;
                const onlyNums = value.replace(/[^\d]/g, "");
                if (onlyNums.length >= 4 && onlyNums.length <= 6) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
                }
                if (onlyNums.length > 6 && onlyNums.length <= 10) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
                }
                if (onlyNums.length > 10) {
                  return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
                }
                return onlyNums;
              }}
            >
              <StyledInput placeholder="202-555-5555" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="showroom"
              rules={[{ required: true, message: "Please select a showroom" }]}
              label={<StyledLabel>Nearest Showroom</StyledLabel>}
            >
              <StyledSelect
                placeholder="Select Showroom"
                options={showroomOptions}
                onChange={handleShowroomChange}
              />
            </Form.Item>
            <Form.Item name="tenantId" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="city" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="state" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item 
              name="message"
              label={<StyledLabel>Comments</StyledLabel>}
            >
              <StyledTextArea
                placeholder="Comments / Promo Code"
                rows={3}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <PButton
            data-large-cy="submit"
            ptype="primary"
            pname={submitButton || "GET STARTED"}
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            disabled={loading}
            style={{ opacity: loading ? 0.7 : 1, cursor: loading ? 'not-allowed' : 'pointer' }}
          />
        </Form.Item>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-02.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="limited lifetime warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="a+ bbb rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-04.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </Form>
      <Loading loading={loading} />
    </div>
  );
}
